<template>
  <div v-loading="loading">
    <eden-page-header
      :title="'Cleaner Directory'"
      :subtitle="formatFullName(cleaner)"
    >
      <template slot="actions">
        <cleaner-actions
          v-if="allowAccessFor(['admin', 'operations'])"
          :cleaner="cleaner"
          @update="update"
        >
          <template slot="actions-trigger">
            <el-button type="plain">
              Actions <i class="eden-icon-arrow-down"></i>
            </el-button>
          </template>
        </cleaner-actions>
      </template>
    </eden-page-header>
    <eden-information-card>
      <template slot="content">
        <div class="cleaner-profile">
          <div class="cleaner-profile--summary">
            <eden-image :image-url="cleaner.avatar" :image-size="70" />
            <p class="name">{{ formatFullName(cleaner) }}</p>
            <span class="address">{{ cleaner.address }}</span>
            <el-tag :class="'small'" :type="setType(cleaner.status)">{{
              formatText(cleaner.status)
            }}</el-tag>
            <el-button :type="'plain'" :size="'small'" @click="edit"
              >Edit Profile</el-button
            >
          </div>
          <eden-information-card
            class="no-border"
            :title="'General Information'"
          >
            <template slot="content">
              <el-table
                :class="'general split-cell'"
                :data="general"
                :show-header="false"
                :span-method="arraySpanMethod"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <template v-if="scope.$index < 2">
                      <el-table
                        :class="'inner-table'"
                        :data="scope.row.data"
                        :show-header="false"
                      >
                        <el-table-column>
                          <template slot-scope="scope">
                            <span class="title font-xsm">{{
                              scope.row.label
                            }}</span>
                            <p class="text font-sm">
                              {{ scope.row.text }}
                            </p>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                    <template v-else>
                      <span class="title font-xsm">{{ scope.row.label }}</span>
                      <p class="text font-sm">
                        {{ scope.row.text }}
                      </p>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
          <eden-information-card
            v-for="(guarantor, index) in guarantors"
            :key="index"
            class="no-border min-width"
            :title="`Guarantor #${index + 1}`"
          >
            <template slot="content">
              <el-table
                :class="'general'"
                :data="guarantor"
                :show-header="false"
                :span-method="arraySpanMethod"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="title font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
        </div>
      </template>
    </eden-information-card>
    <cleaner-orders />
  </div>
</template>

<script>
import CleanerOrders from "@/components/Logistics/Directory/Cleaners/Cleaner/CleanerOrders.vue";
import CleanerActions from "@/components/Logistics/Directory/Cleaners/Cleaner/Actions/CleanerActions";

import cleaners from "@/requests/logistics/directory/cleaners";

export default {
  name: "Cleaner",
  components: { CleanerActions, CleanerOrders },

  data() {
    return {
      loading: false,
      cleaner: {
        guarantors: [],
      },
    };
  },

  computed: {
    cleanerId() {
      return this.$route.params.id;
    },
    general() {
      return [
        {
          data: [
            {
              label: "Phone Number",
              text: this.formatPhoneNumber(this.cleaner.phone_number) || "-",
            },
            {
              label: "Alternate Phone Number",
              text:
                this.formatPhoneNumber(this.cleaner.alternative_phone) || "-",
            },
          ],
        },
        {
          data: [
            {
              label: "City",
              text:
                this.cleaner.location_area && this.cleaner.location_area.name,
            },
            {
              label: "Gender",
              text: this.formatText(this.cleaner.gender) || "-",
            },
          ],
        },
        {
          label: "Preferred working days",
          text:
            this.cleaner.preferred_working_days &&
            this.formatToTitleCase(
              this.sortWeekDays(
                this.cleaner.preferred_working_days,
                "short"
              ).join(", ")
            ),
        },
      ];
    },
    guarantors() {
      const [guarantor_one = {}, guarantor_two = {}] = this.cleaner.guarantors;

      return [
        [
          {
            label: "Name",
            text: this.formatFullName(guarantor_one) || "-",
          },
          {
            label: "Phone Number",
            text: this.formatPhoneNumber(guarantor_one.phone_number),
          },
          {
            label: "Address",
            text: guarantor_one.address,
          },
        ],
        [
          {
            label: "Name",
            text: this.formatFullName(guarantor_two),
          },
          {
            label: "Phone Number",
            text: this.formatPhoneNumber(guarantor_two.phone_number),
          },
          {
            label: "Address",
            text: guarantor_two.address,
          },
        ],
      ];
    },
  },
  created() {
    this.getCleaner();
  },
  methods: {
    getCleaner() {
      this.loading = true;
      cleaners
        .get(this.cleanerId)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.cleaner = data[0];
          } else {
            this.$message.error(response.data.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    edit() {
      this.$router.push({
        name: "logistics.directory.cleaner-edit",
        params: { id: this.cleanerId },
      });
    },
    update(action) {
      if (action === "delete") {
        this.$router.push({
          name: "logistics.directory.index",
          query: { t: "cleaners" },
        });
      } else {
        this.cleaner.status =
          this.cleaner.status === "active" ? "inactive" : "active";
      }
    },
    arraySpanMethod({ rowIndex }) {
      if (rowIndex < 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$profile-width: 320px;

.information-card {
  padding: 0;
  margin-bottom: 48px;
  min-width: unset;

  &.no-border {
    border: 0;
    margin-bottom: 0;
    padding: 16px 0;
    width: 322px;

    &.min-width {
      width: 231px;
    }

    div.title {
      padding: 16px !important;
    }

    @media (min-width: 768px) {
      &:not(:last-child) {
        border-right: 1px solid #f0f4f2;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    @media (max-width: 1200px) {
      border-top: 1px solid #f0f4f2;
      border-radius: 0;
      width: 100%;
    }
  }
}

.cleaner-profile {
  display: flex;
  align-items: center;

  &--summary {
    padding: 16px 0;
    max-width: $profile-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      margin: 7px 0;
      font-weight: 500;
      font-size: 1.125rem;
    }

    .address {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
      display: block;
      margin-bottom: 5px;
    }

    .el-button {
      margin: 15px auto 5px;
    }
  }

  @media (min-width: 1300px) {
    .cleaner-profile--summary {
      border-right: 1px solid #f0f4f2;
    }
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
}

.general {
  tr:last-child {
    td {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}
.inner-table {
  tbody {
    display: flex;

    tr {
      width: 50%;
      padding: 12px;
      &:nth-child(2) {
        border-left: 1px solid #ebeef5;
      }
    }
  }
}
</style>
