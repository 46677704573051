<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-user'" :command="'status'"
          >Change Cleaner Status</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-settings'" :command="'password-reset'"
          >Reset password</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-delete'" :command="'delete'"
          >Delete Cleaner</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :cleaner="cleaner"
      @success="$emit('update', action.command)"
    />
  </div>
</template>

<script>
export default {
  name: "CleanerActions",
  props: {
    cleaner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CleanerDelete: () => import("./CleanerDelete"),
    CleanerStatus: () => import("./CleanerStatus"),
    CleanerPasswordReset: () =>  import("./CleanerPasswordReset"),
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
    };
  },
  methods: {
    command(command) {
      this.action.component = `cleaner-${command}`;
      this.action.command = command;
      setTimeout(() => {
        this.action.visibility = true;
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped></style>
